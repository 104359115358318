import React, { useState, useEffect, useContext, createContext } from "react";
import { useRouter } from "next/router";
import apiClient from "services/apiClient";
const globalContext = createContext();

export function ProvideContext({ children }) {
  const auth = useProvideGlobalContext();
  return (
    <globalContext.Provider value={auth}>{children}</globalContext.Provider>
  );
}

export const useGlobalContext = () => {
  return useContext(globalContext);
};

function useProvideGlobalContext() {
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [statsData, setStatsData] = useState(null);
  const [filterRoute, setFilterRoute] = useState("");
  const router = useRouter();
  const [startDate, setStartDate] = useState("");
  const [finishDate, setFinishDate] = useState("");

  const login = async (email, password) => {
    let response;
    try {
      response = await apiClient.post("/api/login", { email, password });

      localStorage.setItem("jwt-ist", response.data.access_token);
      apiClient.defaults.headers.common.Authorization = `Bearer ${response.data.access_token}`;
      return [true, response.data];
    } catch (error) {
      return [false, error?.response?.data?.message || ""];
    }
  };

  const logout = () => {
    localStorage.removeItem("jwt-ist");
    setUser(null);
  };

  const sendPasswordResetEmail = async (email) => {
    try {
      await apiClient.post("/api/forget", {
        email,
      });
      return true;
    } catch (error) {
      return false;
    }
  };
  const userNotLoggedIn = () => {
    setLoading(false);
    setUser(null);
    if (!["/login"].includes(router.pathname)) {
      router.push("/auth/login");
    }
  };

  const fetchUser = async () => {
    const jwt = localStorage.getItem("jwt-ist");

    if (!jwt) {
      userNotLoggedIn();
      return;
    } else {
      apiClient.defaults.headers.common.Authorization = `Bearer ${jwt}`;
    }
    try {
      const userReply = await apiClient.get("/api/user");
      if (userReply.status !== 200) {
        userNotLoggedIn();
        return;
      }
      setLoading(false);
      if (userReply.data !== user) {
        setUser(userReply.data);
      }
    } catch (error) {
      console.error(error);
      userNotLoggedIn();
      return;
    }
  };

  useEffect(() => {
    if (["/login", "/logout"].includes(router.pathname)) {
      return;
    }
    fetchUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(false);
  }, [statsData]);

  const changeRoute = (route) => {
    setLoading(true);
    setFilterRoute(route);
  };
  const loadingAndStartDate = (startDate) => {
    setLoading(true);
    setStartDate(startDate);
  };
  const loadingAndFinishDate = (finishDate) => {
    setLoading(true);
    setFinishDate(finishDate);
  };

  return {
    loading,
    user,
    login,
    logout,
    statsData,
    setStatsData,
    sendPasswordResetEmail,
    startDate,
    setStartDate: loadingAndStartDate,
    finishDate,
    setFinishDate: loadingAndFinishDate,
    filterRoute,
    setLoading,
    setFilterRoute: changeRoute,
  };
}
